import React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { ScrollerDots, TrilingualCopy, AddToCart, Layout, Grid, ProductHeader, ReviewsBanner, OtherProductCell, MidBanner } from '@components'
import { WithData, rgbaColorFromKey, useSiteDispatch, cdnPath } from '@utilities'
import { palette, typeStyle } from '@theme'
import { findNextProduct } from './SingleProductPage'


interface VarietyPackPageProps {
  data: GatsbyTypes.varietyProductQuery
}


const VarietyPackPage = ({ data: { sanityProduct, otherSanityProducts, allShopifyProduct, sanitySiteSettings }}: VarietyPackPageProps) => {
  if(!sanityProduct || !otherSanityProducts || !allShopifyProduct || !sanitySiteSettings) return <></>
  const {
    slug,
    title,
    summary,
    description,
    reviewsAndPix,
    centerReviewImage,
    canImage,
    bannerImage,
    mainColor,
    mainColorForeground,
    altColor,
    accentSticker,
  } = sanityProduct
  const findProductVariant = (handle?: string) => {
    const d = allShopifyProduct.nodes.find(p => p.handle === handle)
    return d?.variants?.[0]
  }

  const dispatch = useSiteDispatch()
  const showIngredients = (otherProduct: Partial<GatsbyTypes.SanityProduct>) => dispatch({
    type: 'setIngredientShelf',
    payload: {
      copy: otherProduct.longIngredients,
      image: otherProduct.nutritionFactsImage?.asset?.url ?? '',
      color: otherProduct.mainColor ?? 'black',
      colorForeground: otherProduct.mainColorForeground || 'white',
    }
  })

  const flavorCount = Array.from(otherSanityProducts?.nodes).filter(p => p.type === 'flavor').length
  const panelRef = React.useRef<HTMLDivElement>(null)
  const [activeDot, setActiveDot] = React.useState(0)
  const handleScroll = (e: any) => {
    const scroller = e.target as HTMLDivElement
    setActiveDot(Math.floor(scroller.scrollLeft / scroller.scrollWidth * flavorCount))
  }
  React.useEffect(() => {
    if(!panelRef.current) return
    panelRef.current.addEventListener("scroll", handleScroll)
    return () =>  panelRef.current?.removeEventListener("scroll", handleScroll)
  }, [panelRef])

  const nextProduct = findNextProduct( slug!.current!, otherSanityProducts.nodes as Array<Partial<GatsbyTypes.SanityProduct>>)

  return (
    <Layout>
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>
      <Grid>
        <ProductHeader {...{ title, summary, description, canImage, slug, mainColor, mainColorForeground, altColor, accentSticker, nextProduct }} varietyPack >
          <StyledAddToCart variant={findProductVariant(slug?.current)} {...{ altColor }} />
        </ProductHeader>
        <FlavorCellsWrapper>
          <FlavorCells ref={panelRef} >
            <StyledGrid>
              {Array.from(otherSanityProducts?.nodes).filter(p => p.type === 'flavor').map(otherProduct => !!otherProduct && (
                <FlavorCell key={otherProduct._id} >
                  {otherProduct.icon && (
                    <Icon src={cdnPath(otherProduct.icon.asset?.url)} />
                  )}
                  <Title>{otherProduct.title}</Title>
                  <Description>
                    <TrilingualCopy>{otherProduct.description}</TrilingualCopy>
                  </Description>
                  <Button onClick={() => showIngredients(otherProduct as Partial<GatsbyTypes.SanityProduct>)} >see all ingredients</Button>
                </FlavorCell>
              ))}
            </StyledGrid>
          </FlavorCells>
          <ScrollerDots {...{ activeDot }} count={flavorCount}/>
        </FlavorCellsWrapper>
        <MidBanner image={bannerImage?.asset?.url} />
        {reviewsAndPix && reviewsAndPix.length > 0 && (
          <ReviewsBanner {...{ reviewsAndPix, mainColor, mainColorForeground, altColor, centerReviewImage }} />          
        )}
        {/* otherproductcell: three wide */}
        {Array.from(otherSanityProducts?.nodes).filter(p => p.type === 'flavor').map(otherProduct => !!otherProduct && (
          <StyledOtherProductCell product={otherProduct as Partial<GatsbyTypes.SanityProduct>} key={otherProduct._id} variant={findProductVariant(otherProduct.slug?.current) as GatsbyTypes.ShopifyProductVariant} />
        ))}
      </Grid>
    </Layout>
  )
}


const FlavorCellsWrapper = styled.div`
  display: contents;
  @media only screen and (max-width: 743px) {
    display: block;
    position: relative;
    background: white;
    max-width: calc(100vw - 22px);
  }
  
`


const FlavorCells = styled.div`
  @media only screen and (max-width: 743px) {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-bottom: 80px;
  }
  @media only screen and (min-width: 744px) {
    display: contents;
  }
`


const Icon = styled.img``


const Title = styled.h1`
  ${typeStyle.eyebrow}
`

const Description = styled.div`
  margin-bottom: 2em;
`


const Button = styled.button`
  ${typeStyle.button}
`


const StyledGrid = styled(props => <Grid {...props} />)<{
  fx: GatsbyTypes.Maybe<string>
}>`
  @media only screen and (max-width: 743px) {
    grid-template-columns: repeat(9999, 1fr) !important;
    grid-column-gap: 1px;
    > div {
      grid-column-end: span 1;
      min-width: 100vw;
      min-height: 80vw;
      scroll-snap-align: center;
      box-sizing: border-box;
      padding: 0 10vw;
    }
  }
  @media only screen and (min-width: 744px) {
    display: contents;
  }
`



const FlavorCell = styled.div`
  grid-column-end: span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 4;
    padding: 2vw 4vw 6vw 4vw;
  }
  @media only screen and (min-width: 1440px) {
    padding: 29px 58px 86px 58px;
  }
  background: ${palette.white};
`



const StyledOtherProductCell = styled(props => <OtherProductCell {...props} />)`
  @media only screen and (min-width: 744px) {
    grid-column-end: span 4;
  }
`



const mapDataToProps = (data: GatsbyTypes.productQuery) => ({ ...data })


export const query = graphql`
  query varietyProduct($slug: String!) {
    sanitySiteSettings {
      ...siteSettingsFields
    }
    sanityProduct(slug: {current: {eq: $slug}}) {
      ...productFields
    }
    otherSanityProducts: allSanityProduct(filter: {active: {eq: true}}) {
      nodes {
        _id
        _key
        slug {
          current
        }
        type
        title
        canImage {
          asset {
            url
          }
        }
        mainColor
        mainColorForeground
        accentSticker
        icon {
          asset {
            url
          }
        }
        mainColor
        mainColorForeground
        summary {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
        description {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
        longIngredients {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
        nutritionFactsImage {
          asset {
            url
          }
        }
      }
    }
    allShopifyProduct {
      nodes {
        title
        handle
        variants {
          price
          storefrontId
          inventoryQuantity
          title
        }
      }
    }
  }
`


const StyledAddToCart = styled(props => <AddToCart {...props} />)<{ altColor?: string }>`
  @media only screen and (max-width: 743px) {
    > * {
      padding: 45px;
      grid-column-end: span 1;
    }
    div:nth-of-type(2) {
      display: flex;
      flex-direction: column-reverse;
      ${typeStyle.footnote}
      font-size: 20px;
      color: ${props => rgbaColorFromKey(props.altColor, 1)};
      > div {
        margin-bottom: 1em;
      }
    }
  }
`


export default WithData(mapDataToProps, VarietyPackPage)